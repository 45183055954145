'use strict';

let toggleBtn = document.querySelector('.c-header__toggle-btn');
let navWrap = document.querySelector('.c-nav');
let navList = document.querySelector('.c-nav__list');

toggleBtn.addEventListener('click', function (event) {
  navWrap.classList.toggle('is-active');
  navList.classList.toggle('is-active');
  toggleBtn.classList.toggle('is-active');
});